/**
 * @file
 * Styles for Featured Products
 */

@import "variables";

$gray-med:        #999;
$red-dark:        #7c000a;
$blue:            #023c72;
$orange:          #f2ab01;

$gray-1: #f9f9f9;
$gray-2: #eee;
$gray-3: #ccc;
$gray-4: #aaa;

$gray-1-link: $grey;
$gray-2-link: $grey;
$gray-3-link: $grey;
$gray-4-link: $grey;


//
// Featured Products Block
//

// Defaults
#block-views-featured-products-block,
#block-views-featured-products-page-block {
  width: 100%;
  display: inline-block;
  padding-right: 20px;
  margin-bottom: 20px;
  position: relative;
  
  @media only screen and (max-width: 767px) {
    padding-right: 0;
  }

  // All Products
  .views-row {
    @include clearfix();
    float: left;
    width: 41%;
    margin: 0;
    height: 110px;
    font-family: $primary-typeface;
    font-size: $fs-m;
    line-height: 1em;
    position: relative;
    
    &:hover {
      .views-field-nothing {
        background: $orange;
      }
    }
    
    .views-field-nothing {
      background: $gray-1;
      padding: 10px;
      @include box-shadow(inset 130px 90px 127px rgba(black, 0.05));
      border: 2px solid white;
      border-bottom: none;
      border-left: none;
      @include transition(
        all 0.2s ease-in-out
      );
    }
    
    // Image
    .image {
      float: left;
      line-height: 0;
      margin: 0 10px 0 0;
      background: $white;
      
      a {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
      
      img {
        max-height: 100%;
        width: auto;
        height: 90px;
        margin: 10px;
      }
      
    }
    
    // Title 
    .views-field-title {
      height: 90px;
      display: table-cell;
      vertical-align: middle;
      
      a {
        color: $red-dark;
        display: block;
        margin-left: 100px;
      }
      
    }
    
  }

  .views-row-1 {  
    height: 220px;
    width: 59%;
    font-size: $fs-xxl;
    font-family: $secondary-typeface;
    
    // Image
    .image {
      img {
        margin: 10px; 
        height: 200px;
      }
    }
    
    // Title 
    .views-field-title {
      height: 200px;
      
      a {
        margin-left: 210px;
      }
      
    }
    
  }
  
  // Gray 1
  .views-row-1 {
    .views-field-nothing {
      background: $gray-1;
      a {
        color: $gray-1-link;
      }
    }
  }

  // Gray 2
  
  // Gray 3 
  
  // Gray 4
  .views-row-2, .views-row-3 {
    .views-field-nothing {
      background: $gray-4;
      a {
        color: $gray-4-link;
      }
    }
  }
  
  // Hide These
  .views-row-4, .views-row-5, .views-row-6, .views-row-7, .views-row-8, .views-row-9, .views-row-10, .views-row-11, .views-row-12, .views-row-13 { display: none; }
  
}

// Various Screen Sizes
#block-views-featured-products-block,
#block-views-featured-products-page-block {
  
  // Default 960-1200
  // 3
  
  @media only screen and (min-width: 571px) and (max-width: 767px) {
    // 3
    .views-row {
      position: absolute;
      right: 0;
      width: inherit;
      
      .views-field-nothing {
        @include box-shadow(inset 25px -30px 17px rgba(black, 0.05))
      }
      
      .image {
        float: none;
        margin: 0;
      }
      
      .views-field-title {
        display: none;
      }
      
    }
    
    
    
    .views-row-3 {
      top: 110px;
    }
    
    .views-row-1 {
      width: 100%;
      padding-right: 110px;
      position: static;
      
      .views-field-nothing {
        @include box-shadow(inset 130px 90px 127px rgba(black, 0.05));
      }
      
      .image {
        float: left;
        margin-right: 10px;
      }
      
      .views-field-title {
        display: table-cell;
      }
      
    }
    
  }
  
  @media only screen and (min-width: 768px) and (max-width: 959px), (max-width: 570px) {
    // 2 
    
    .views-row {
      width: 100%;
      margin: 0;
    }
    
    .views-row-1 {
      height: 110px;
      font-family: $primary-typeface;
      font-size: $fs-m;
      
      .image {
        img {
          height: 80px;
          margin: 5px;        
        }
      }
      
      .views-field-title {
        height: 90px;
      }
      
    }
    
    // Hide These
    .views-row-3 {
      display: none;
    }
    
    // Gray 1
    .views-row-1 {
      .views-field-nothing {
        background: $gray-1;
        a {
          color: $gray-1-link;
        }
      }
    }

    // Gray 2
    
    // Gray 3
    
    // Gray 4
    .views-row-2, .views-row-3 {
      .views-field-nothing {
        background: $gray-4;
        a {
          color: $gray-4-link;
        }
      }
    }
    
  }  
  
  @media only screen and (min-width: 1450px) {
    // Show 5
    
    // Default Views Row Width
    .views-row {
      width: 32.333333%;
    }
    
    // + Show These
    .views-row-4, .views-row-5 {
      display: block;
    }
    
    // Set These to Large Width
    .views-row-1 {
      width: 35.333333%;
    }
        
    // Gray 1
    .views-row-1 {
      .views-field-nothing {
        background: $gray-1;
        a {
          color: $gray-1-link;
        }
      }
    }

    // Gray 2
    
    // Gray 3
    .views-row-3, .views-row-5 {
      .views-field-nothing {
        background: $gray-3;
        a {
          color: $gray-3-link;
        }
      }
    } 
    
    // Gray 4
    .views-row-2, .views-row-4 {
      .views-field-nothing {
        background: $gray-4;
        a {
          color: $gray-4-link;
        }
      }
    }
    
  }
  
  @media only screen and (min-width: 1550px) {
    // 7
    // Default Views Row Width
    .views-row {
      width: 17.5%;
    }
    
    // + Show These
    .views-row-6, .views-row-7 {
      display: block;
    }
    
    // Set These to Medium Width
    .views-row-1, .views-row-2, .views-row-5 {
      width: 32.5%;
      font-family: $secondary-typeface;
      font-size: $fs-xl;
    }
    
    // Gray 1
    .views-row-1 {
      .views-field-nothing {
        background: $gray-1;
        a {
          color: $gray-1-link;
        }
      }
    }

    // Gray 2
    .views-row-4, .views-row-7 {
      .views-field-nothing {
        background: $gray-2;
        a {
          color: $gray-2-link;
        }
      }
    }
    
    // Gray 3
    .views-row-3, .views-row-6 {
      .views-field-nothing {
        background: $gray-3;
        a {
          color: $gray-3-link;
        }
      }
    } 
    
    // Gray 4
    .views-row-2, .views-row-5 {
      .views-field-nothing {
        background: $gray-4;
        a {
          color: $gray-4-link;
        }
      }
    }
    
  }
  
  @media only screen and (min-width: 1950px) {
    // 9
    .views-row {
      width: 14.833333%;
    }
    
    // + Show These
    .views-row-8, .views-row-9 {
      display: block;
    }
    
    // Set This to Lg Width
    .views-row-1 {
      width: 31.5%;
    }
    
    // Set These to Medium Width
    .views-row-2, .views-row-6 {
      width: 24%;
      font-family: $secondary-typeface;
      font-size: $fs-xl;
    }

    // Reset row 5
    .views-row-5 {
      font-family: $primary-typeface;
      font-size: $fs-m;
    }
    
    // Gray 1
    .views-row-1, .views-row-5, .views-row-9 {
      .views-field-nothing {
        background: $gray-1;
        a {
          color: $gray-1-link;
        }
      }
    }

    // Gray 2
    .views-row-4, .views-row-8 {
      .views-field-nothing {
        background: $gray-2;
        a {
          color: $gray-2-link;
        }
      }
    }
    
    // Gray 3
    .views-row-3, .views-row-7 {
      .views-field-nothing {
        background: $gray-3;
        a {
          color: $gray-3-link;
        }
      }
    } 
    
    // Gray 4
    .views-row-2, .views-row-6 {
      .views-field-nothing {
        background: $gray-4;
        a {
          color: $gray-4-link;
        }
      }
    }
    
  }
  
  @media only screen and (min-width: 2200px) {
    // 11
    .views-row {
      margin: 0;
      width: 13.03333%;
    }
    
    // Set This to Lg Width
    .views-row-1 {
      width:  26.5%;
    }
    
    // Set These to Medium Width
    .views-row-2, .views-row-7 {
      width: 21%;
      font-family: $secondary-typeface;
      font-size: $fs-xl;
    }
    
    // Reset row 6
    .views-row-6 {
      font-family: $primary-typeface;
      font-size: $fs-m;
    }
    
    // + Show These
    .views-row-10, .views-row-11 {
      display: block;
    }
    
    // Gray 1
    .views-row-1, .views-row-5, .views-row-10 {
      .views-field-nothing {
        background: $gray-1;
        a {
          color: $gray-1-link;
        }
      }
    }

    // Gray 2
    .views-row-4, .views-row-9 {
      .views-field-nothing {
        background: $gray-2;
        a {
          color: $gray-2-link;
        }
      }
    }
    
    // Gray 3
    .views-row-3, .views-row-8 {
      .views-field-nothing {
        background: $gray-3;
        a {
          color: $gray-3-link;
        }
      }
    } 
    
    // Gray 4
    .views-row-2, .views-row-7, .views-row-6, .views-row-11 {
      .views-field-nothing {
        background: $gray-4;
        a {
          color: $gray-4-link;
        }
      }
    }
    
  }
  
}
